import "./Footer.css";

export function Footer() {
  return (
    <footer>
      <div className="footer-content">
        <h1>Zobacz inne projekty!</h1>
        <ul className="list-links">
          <a href="https://szwecja-praktyki.zsl.gliwice.pl/" id="projects">
            Szwecja
          </a>
          <a href="https://zsl.gliwice.pl/" id="projects">
            ZSŁ Gliwice
          </a>
          <a href="https://hiszpania2023.zsl.gliwice.pl" id="projects">
            Hiszpania
          </a>
        </ul>
        <ul className="socials">
          <a href="https://www.facebook.com/zespolszkollacznosci/?locale=pl_PL">
            <img src="img/facebook.jpeg" alt="facebook" id="social"></img>
          </a>
          <a href="https://www.instagram.com/europejskapraktyka_zsl_gliwice/?igshid=ZGUzMzM3NWJiOQ%3D%3D">
            <img src="img/instagram.jpeg" alt="facebook" id="social"></img>
          </a>
        </ul>
      </div>
    </footer>
  );
}
