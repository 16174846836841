import "../Zakwaterowanie.css";
import { LinkHome } from "./LinkHome";

export default function Zakwaterowanie() {
  return (
    <>
      <div className="zakwaterowanie-wrapper">
        <div className="hotel">
          <h1 id="hotel">Hotel Eden</h1>
          <div className="zakwaterowanie-description">
            <p>
              Zakwaterowanie mieliśmy zapewnione w Hotelu Eden. Hotel był
              wspaniale zaaranżowany, a wystrój był charakterystyczny dla
              greckiego stylu. Pokoje były przestronne i ładnie wystrojone, a co
              ciekawe w kabinie prysznicowej było wbudowane radio, którego można
              było posłuchać podczas brania prysznicu. Właścicielką Hotelu jest
              Pani Meri wraz ze swoim mężem Panem Nicosem. Jest to przemiłe
              starsze małżeństwo, które idealnie wpisuje się w denifinicję
              greckiego słowa "Filotimio" oznaczającego otwartość serc greków.
            </p>
          </div>
          <div className="img-wrapper">
            <img
              src="img/mieszkanie1.jpg"
              alt="taras"
              id="img-mieszkanie"
            ></img>
            <img
              src="img/mieszkanie2.jpg"
              alt="recepcja"
              id="img-mieszkanie"
            ></img>
            <img
              src="img/mieszkanie3.jpg"
              alt="stolowka"
              id="img-mieszkanie"
            ></img>
          </div>
        </div>
        <div className="jedzenie-hotel">
          <h1 id="hotel">Jedzenie</h1>
          <div className="zakwaterowanie-description">
            <p>
              Obiady i Kolacje były bardzo syte i pyszne, ponad to na kolację
              zawsze były serwowane ciepłe posiłki. Dania przygotowywał dla nas
              syn Pani Meri-Marcos, który prowadzi dwie restauracje: grecką w
              Leptokarii i meksykańską w Paltamonas. Pan Marcos przyrządzał
              potrawy pod nas, czyli dieta środkowo europejska, lecz doprawiał
              je w greckim stylu, ale nie zabrakło też greckich przysmaków,
              takich jak: Queasadillas, Souvlaki, Tzatziki, Moussaka, Gyros oraz
              Dolmades. Przed obiadem i kolacją zawsze podawano nam przystawki w
              postaci sałatek z sosem, lub oliwą z oliwek tłoczonej przez Panią
              Meri i Pana Nicosa z ich własnej plantacji. Po kolacji Pani Meri
              zawsze częstowała nas ciastem, któresama przygotowywała. Śniadania
              były przygotowywane przez Panią Meri i były w formie szwedzkiego
              stołu. Posiłki na śniadaniu się powtarzały, ale były tak pyszne,
              że ciężko było się powstrzymać od dobierania dokładek. Na
              śniadaniu też nie zabrakło greckich przysmaków, takich jak:
              Spanakopita oraz Baklava.
            </p>
          </div>
          <div className="img-wrapper">
            <img
              src="img/jedzenie/jedzenie1.jpeg"
              alt="jedzenie"
              id="img-jedzenie"
            ></img>
            <img
              src="img/jedzenie/jedzenie2.jpeg"
              alt="jedzenie"
              id="img-jedzenie"
            ></img>
            <img
              src="img/jedzenie/jedzenie4.jpeg"
              alt="jedzenie"
              id="img-jedzenie"
            ></img>
            <img
              src="img/jedzenie/jedzenie3.jpeg"
              alt="jedzenie"
              id="img-jedzenie2"
            ></img>
            <img
              src="img/jedzenie/jedzenie5.jpeg"
              alt="jedzenie"
              id="img-jedzenie2"
            ></img>
            <img
              src="img/jedzenie/jedzenie6.jpeg"
              alt="jedzenie"
              id="img-jedzenie2"
            ></img>
          </div>
        </div>
      </div>
      <LinkHome />
    </>
  );
}
