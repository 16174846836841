import "../Podroz.css";
import { LinkHome } from "./LinkHome";

export default function Podroz() {
  return (
    <>
      <div className="travel-wrapper">
        <div className="travelToGreece-description">
          <h1 id="travelToGreece">Kierunek: Saloniki</h1>
          <p id="travel-description">
            Wczesnym rankiem wyjechaliśmy spod szkoły busem na lotnisko w
            Balicach. Gdy dojechaliśmy udaliśmy się na odprawę i nadanie bagażu
            rejestrowanego. Po bezproblemowym przejściu przez kontrolę
            bezpieczeństwa udaliśmy się pod bramki, lecz mieliśmy jeszcze dużo
            czasu więc wykorzystaliśmy go na zjedzenie śniadania. Kiedy zbliżała
            się godzina wylotu przygotowaliśmy karty pokładowe, aby wejść na
            pokład samolotu. Pod sam samolot zostaliśmy przetransportowani
            busem. Gdy byliśmy już na swoich miejscach a samolot ruszył na pas
            startowy wszyscy zaczęli się stresować. Na szczęście lot minął
            szybko i bezproblemowo.
          </p>
          <div className="img-wrazenia">
            <img
              src="img/podroz/doSalonik/ks1.jpg"
              alt="jedzenie"
              id="img-jedzenie2"
            ></img>
            <img
              src="img/podroz/doSalonik/ks4.jpg"
              alt="jedzenie"
              id="img-jedzenie2"
            ></img>
            <img
              src="img/podroz/doSalonik/ks3.jpg"
              alt="jedzenie"
              id="img-jedzenie2"
            ></img>
          </div>
        </div>
        <div className="salonikiToGreece-description">
          <h1 id="travelToGreece">Pierwsze wrażenia</h1>
          <p id="travel-description">
            Po wylądowaniu bezpiecznie opuściliśmy pokład samolotu i udaliśmy
            się po odbiór naszych bagaży. Na lotnisku już czekała na nas Pani
            Monika, czyli Pani odpowiadająca za organizację naszych wycieczek.
            Zanim udaliśmy się do naszego hotelu zostaliśmy zabrani do
            sieciowej kawiarni “Mikel”. Tam zostaliśmy poczęstowani pysznym,
            zimnym cappuccino z słodkim mlekiem zagęszczonym o raz grecką
            chałką, czyli Tsoureki.
          </p>
          <div className="img-wrazenia">
            <img
              src="img/podroz/wrazenia/w1.jpg"
              alt="jedzenie"
              id="img-jedzenie"
            ></img>
            <img
              src="img/podroz/wrazenia/w2.jpg"
              alt="jedzenie"
              id="img-jedzenie"
            ></img>
            <img
              src="img/podroz/wrazenia/w3.jpg"
              alt="jedzenie"
              id="img-jedzenie"
            ></img>
          </div>
        </div>
        {/* <SlideShowElektrycy /> */}
        <div className="salonikiToGreece-description">
          <h1 id="travelToGreece">Powrót: Zwiedzanie Salonik</h1>
          <p id="travel-description">
            Wracając z praktyk zwiedziliśmy Saloniki między innymi Bazylikę
            świętego Demetriusza, plac Arystotelesa, białą wieże oraz pomnik
            Aleksandra Wielkiego. Gdy wsiedliśmy do autobusu w drodze na
            lotnisko czekały na nas często spotykane w Salonikach korki uliczne
            na szczęście zdążyliśmy na czas na odprawę na lotnisku. Po oddaniu
            bagaży udaliśmy się na bramki bezpieczeństwa na szczęście znowu
            udało nam się przejść przez nie bezproblemowo.
          </p>
          <div className="img-wrazenia">
            <img
              src="img/podroz/saloniki/s7.jpg"
              alt="jedzenie"
              id="img-jedzenie2"
            ></img>
            <img
              src="img/podroz/saloniki/s4.jpg"
              alt="jedzenie"
              id="img-jedzenie2"
            ></img>
            <img
              src="img/podroz/saloniki/s5.jpg"
              alt="jedzenie"
              id="img-jedzenie2"
            ></img>
          </div>
        </div>
        {/* <SlideShowElektrycy /> */}
        <div className="salonikiToGreece-description">
          <h1 id="travelToGreece">Kierunek: Polska</h1>
          <p id="travel-description">
            W samolocie będąc zmęczonym całodniowym zmęczeniem niektórzy poszli
            spać, a niektórzy po prostu czekali, aby znów móc się zobaczyć z
            rodziną na szczęście nie długo musieli czekać na tą chwilę. Przy
            lądowaniu przeżyliśmy szok będąc nad chmurami widzieliśmy piękne
            słońce wraz z pięknym błękitnym niebem, lecz im niżej byliśmy coraz
            bardziej robiło się pochmurnie i szaro. Po wylądowaniu poszliśmy
            odebrać nasze bagaże które były całe i zdrowe. Nasz lot był już w
            pełni zakończony, a ponowne zobaczenie się z rodziną zbliżało się
            wielkimi krokami. Podróż z lotniska w Balicach do Gliwic była
            spokojna i przyjemna.
          </p>
          <div className="img-wrazenia">
            <img
              src="img/podroz/powrot/p1.jpg"
              alt="jedzenie"
              id="img-jedzenie2"
            ></img>
            <img
              src="img/podroz/powrot/p2.jpg"
              alt="jedzenie"
              id="img-jedzenie2"
            ></img>
            <img
              src="img/podroz/powrot/p3.jpg"
              alt="jedzenie"
              id="img-jedzenie2"
            ></img>
          </div>
        </div>
        {/* <SlideShowElektrycy /> */}
      </div>
      <LinkHome />
    </>
  );
}
