import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import '../SlideShow.css'

const fadeImages = [
  {
    url: 'img/grupowe/grupowe2.jpg', alt: '2',
  },
  {
    url: 'img/grupowe/grupowe8.jpg', alt: '8',
  },
  {
    url: 'img/grupowe/grupowe6.jpg', alt: '6',
  },
  {
    url: 'img/grupowe/grupowe4.jpg', alt: '4',
  },
  {
    url: 'img/grupowe/grupowe5.jpg', alt: '5',
  },
  {
    url: 'img/grupowe/grupowe3.jpg', alt: '3',
  },
  {
    url: 'img/grupowe/grupowe1.jpg', alt: '1',
  },
  {
    url: 'img/grupowe/grupowe7.jpg', alt: '7',
  },
];

export function SlideShow() {
  return (
        <div className="slide-container">
          <Fade>
              {fadeImages.map((fadeImage, index) => (
              <div key={index}>
                  <img id='siema' alt={fadeImage.alt} src={fadeImage.url} />
              </div>
              ))}
          </Fade>
        </div>

  )
}