import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import '../SlideShowElektrycy.css'

const fadeImages = [
  {
    url: 'img/elektrycy/e1.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e2.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e3.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e4.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e5.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e6.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e7.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e8.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e9.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e10.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e11.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e12.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e13.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e14.jpeg', alt: '1',
  },
  {
    url: 'img/elektrycy/e15.jpeg', alt: '1',
  },
];

export function SlideShowElektrycy() {
  return (
        <div className="elektry-slide-container">
          <Fade>
              {fadeImages.map((fadeImage, index) => (
              <div key={index}>
                  <img id='slider' alt={fadeImage.alt} src={fadeImage.url} />
              </div>
              ))}
          </Fade>
        </div>

  )
}
