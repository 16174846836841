import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "../Map.css";

export function Map() {
  const hotel = [39.97753, 22.645471];
  const meteory = [39.726258, 21.626213];
  const olimp = [40.028111, 22.503257];
  const pozar = [40.970199, 21.916457];
  const edesa = [40.803891, 22.056052];
  const zamek = [40.005192, 22.597708];
  const larisa = [39.6351, 22.415562];
  const centerPoint = [42.846625, 21.705905];

  return (
    <div id="map-container">
      <MapContainer
        center={centerPoint}
        zoom={7}
        className="map"
        style={{ width: "auto", borderRadius: "20px" }}
      >
        <TileLayer url="https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=32CDuNx5Er1gCk66iqSN" />
        <Marker position={hotel}>
          <Popup>Hotel Eden</Popup>
        </Marker>
        <Marker position={edesa}>
          <Popup>Edesa</Popup>
        </Marker>
        <Marker position={pozar}>
          <Popup>Pozar</Popup>
        </Marker>
        <Marker position={olimp}>
          <Popup>Olimp</Popup>
        </Marker>
        <Marker position={meteory}>
          <Popup>Meteory</Popup>
        </Marker>
        <Marker position={larisa}>
          <Popup>Larisa</Popup>
        </Marker>
        <Marker position={zamek}>
          <Popup>Zamek w Platamonas</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}
