import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import '../SlideShowInformatycy.css'

const fadeImages = [
  {
    url: 'img/informatycy/i1.jpeg', alt: '1',
  },
  {
    url: 'img/informatycy/i6.jpg', alt: '1',
  },
  {
    url: 'img/informatycy/i7.jpg', alt: '1',
  },
  {
    url: 'img/informatycy/i9.jpg', alt: '1',
  },
  {
    url: 'img/informatycy/i10.jpg', alt: '1',
  },
  {
    url: 'img/informatycy/i12.jpeg', alt: '1',
  },
  {
    url: 'img/informatycy/i13.jpeg', alt: '1',
  },
  {
    url: 'img/informatycy/i14.jpeg', alt: '1',
  },
];

export function SlideShowInformatycy() {
  return (
        <div className="inf-slide-container">
          <Fade>
              {fadeImages.map((fadeImage, index) => (
              <div key={index}>
                  <img id='slider' alt={fadeImage.alt} src={fadeImage.url} />
              </div>
              ))}
          </Fade>
        </div>

  )
}

