import "../Kafelki.css";
import { Outlet, Link } from "react-router-dom";
import { SlideShow } from "./SlideShow";
import { Map } from "./Map";
import { Footer } from "../Footer";

export default function Kafelki() {
  return (
    <>
      <div className="grid-wrapper">
        <div className="wide">
          <img src="img/7.jpg" alt="siema"></img>
          <Link to="/projekt">
            <div className="overlay">
              <h5>O projekcie</h5>
              <h6>Dowiedz się więcej o naszych praktykach!</h6>
            </div>
          </Link>
        </div>
        <div className="tall">
          <img src="img/kafelek2.png" alt="siema"></img>
          <Link to="/wycieczki">
            <div className="overlay">
              <h5>Wycieczki</h5>
              <h6>
                Dowiedz się więcej o wycieczkach w których braliśmy udział!
              </h6>
            </div>
          </Link>
        </div>
        <div className="wide">
          <img src="img/zakwaterowanie3.jpg" alt="siema"></img>
          <Link to="/zakwaterowanie">
            <div className="overlay">
              <h5>Zakwaterowanie</h5>
              <h6>Dowiedz się więcej o naszym hotelu!</h6>
            </div>
          </Link>
        </div>
        <div className="container">
          <img src="img/praca1.jpeg" alt="siema"></img>
          <Link to="/praca">
            <div className="overlay">
              <h5>Praca</h5>
              <h6>Dowiedz się więcej o naszej pracy!</h6>
            </div>
          </Link>
        </div>
        <div className="tall">
          <img src="img/podroz.jpg" alt="siema"></img>
          <Link to="/podroz">
            <div className="overlay">
              <h5>Podróż</h5>
              <h6>Dowiedz się więcej o naszej podróży!</h6>
            </div>
          </Link>
        </div>
        <div className="container">
          <img src="img/galeria.jpg" alt="siema"></img>
          <Link to="/galeria">
            <div className="overlay">
              <h5>Galeria</h5>
              <h6>
                Zobacz więcej zdjęć z naszego wyjazdu!
              </h6>
            </div>
          </Link>
        </div>
        <div className="container">
          <img src="img/ania.jpg" alt="siema"></img>
          <Link to="/opinie">
            <div className="overlay">
              <h5>Opinie</h5>
              <h6>
                Zobacz opinie o naszym projekcie!
              </h6>
            </div>
          </Link>
        </div>
        <div className="container">
          <img src="img/miasto.jpg" alt="siema"></img>
          <Link to="/miasto">
            <div className="overlay">
              <h5>Nei Pori</h5>
              <h6>
                Dowiedz się więcej o naszej miejscowości
              </h6>
            </div>
          </Link>
        </div>
        <div className="wide">
          <img src="img/kafelek.png" alt="siema"></img>
          <Link to="/wolne">
            <div className="overlay">
              <h5>Czas Wolny</h5>
              <h6>Sprawdź jak spędzaliśmy czas wolny!</h6>
            </div>
          </Link>
        </div>
      </div>
      <Outlet />
      <div className="content">
        <SlideShow />
        <Map />
      </div>
      <Footer />
    </>
  );
}
