import { LinkHome } from "./LinkHome";
import "../Wolne.css";

export default function Wolne() {
  return (
    <>
      <div className="wolne-wrapper">
        <div className="siema1234">
          <h1 id="siema153">Czas Wolny</h1>
          <p id="free-time">
            Czas na relaks jest równie ważny co ciężka praca. Po pracy mieliśmy
            okazję zobaczyć przepiękne widoki u wybrzeży Morza Egejskiego.
            Malownicze tereny zapierały dech w piersiach podczas spacerów wzdłuż
            promenady. Grecy bardzo cenią sobie przerwę na kawę, więc idąc ich
            przykładem spróbowaliśmy kultowej greckiej kawy z mlekiem
            skondensowanym. Smak ten towarzyszył nam do końca wyjazdu. Mieliśmy
            także okazję spróbować takich potraw jak Souvlaki lub Moussaka.
            Wzięliśmy także udział w grze terenowej, w której za zadanie
            mieliśmy znaleźć poszczególne obiekty w naszym mieście i zrobić z
            nimi zdjęcie. Zabawa była przednia, a dzięki niej mogliśmy dosadnie
            zwiedzić okolicę. Dzięki zorganizowanym wycieczkom w weekendy,
            mogliśmy zwiedzić wiele wspaniałych miejsc oraz doświadczyć
            niesamowitych przeżyć takich jak: Największy wodospad grecki
            znajdujący się w Edessie - liczący 70 metrów oraz kąpiel w
            relaksacyjnych gorących źródłach w Pozarze. Spacer po wąwozie u stóp
            Olimpu oraz zwiedzenie zabytkowego zamku w Platamonas. Zwiedzenie
            klasztorów na szczytach Meteorów.
          </p>
          <a id="galeria-wolne" href="./#/galeria">
            Zobacz więcej zdjęć z czasu wolnego!
          </a>
        </div>
      <div className="img-wrazenia">
        <img
          src="img/wolne/k1.jpg"
          alt="jedzenie"
          id="img-poziome"
        ></img>
        <img
          src="img/wolne/k3.jpg"
          alt="jedzenie"
          id="img-poziome"
        ></img>
        <img
          src="img/wolne/k4.jpg"
          alt="jedzenie"
          id="img-poziome"
        ></img>
      </div>
      </div>
      <LinkHome />
    </>
  );
}
