import "./App.css";
import { Routes, Route } from "react-router-dom";
import Podroz from "./pages/Podroz";
import Zakwaterowanie from "./pages/Zakwaterowanie";
import Praca from "./pages/Praca";
import Miasto from "./pages/Miasto";
import Wolne from "./pages/Wolne";
import Wycieczki from "./pages/Wycieczki";
import Galeria from "./pages/Galeria";
import NoPage from "./pages/NoPage";
import Projekt from "./pages/Projekt";
import Opinie from "./pages/Opinie";
import Kafelki from "./pages/Kafelki";
import Domi from "./pages/Domi"

function App() {
  return (
    <Routes id="nav-bar-elements">
      <Route index element={<Kafelki />} />
      <Route path="projekt" element={<Projekt />} />
      <Route index element={<Kafelki />} />
      <Route path="podroz" element={<Podroz />} />
      <Route path="zakwaterowanie" element={<Zakwaterowanie />} />
      <Route path="galeria" element={<Galeria />} />
      <Route path="miasto" element={<Miasto />} />
      <Route path="praca" element={<Praca />} />
      <Route path="wolne" element={<Wolne />} />
      <Route path="wycieczki" element={<Wycieczki />} />
      <Route path="opinie" element={<Opinie />} />
      <Route path="domi" element={<Domi />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
}

export default App;
