import "../Praca.css";
import { LinkHome } from "./LinkHome";
import { SlideShowElektrycy } from "./SlideShowElektrycy";
import { SlideShowInformatycy } from "./SlideShowInformatycy";
import { SlideShowLogistyk } from "./SlideShowLogistyk";

export default function Praca() {
  return (
    <>
      <div className="work-body">
        <LinkHome />
        <div className="work-container">
          <div className="elektryk">
            <SlideShowElektrycy />
            <div className="info-elektryk">
              <h1>Elektrycy</h1>
              <p id="elektrycy">
                Elektrycy wykonywali oni swój zawód w różnych miejscowościach w
                okolicy, ich zakres obowiązków był bardzo szeroki, ponieważ
                zawód elektryka nie ogranicza się tylko do podłączania
                przewodów, lecz wymaga on wykonywania różnych prac budowlanych.
                Jednymi z głównych obowiązków należących do elektryków bylo:
                Przeprowadzanie przewodów w ścianie lub ziemi, kucie oraz
                szpachlowanie otworów po przeprowadzonych przewodach, tzw. biały
                montaż czyli montaż gniazdek, lamp oraz przełączników różnych
                maści oraz montaż i podłączanie skrzynek rozdzielczych.
                Elektrycy są zadowoleni ze współpracy z mentorem oraz jego
                pracownikiem. Osoby z którymi pracowali były uprzejme i mile
                nastawione.
              </p>
            </div>
          </div>
          <div className="informatyk">
            <div className="info-informatyk">
              <h1>Informatycy</h1>
              <p id="informatycy">
                Informatycy mieli szeroki zakres zadań. Tworzyli strony i gry
                komputerowe, ale także programowali roboty oraz drony. Praca
                była przyjemna i nie sprawiała im większych trudności. Każdy ze
                sobą dobrze współpracował, dzięki czemu zadawane im zadania były
                wykonywane bardzo sprawnie. Relacje z mentorem były przyjazne.
                Zawsze był on miły i chętny do pomocy. Informatycy są bardzo
                zadowoleni ze swojej pracy.
              </p>
            </div>
            <SlideShowInformatycy />
          </div>
          <div className="logistyk">
            <SlideShowLogistyk />
            <div className="info-logistyk">
              <h1>Logistyczka</h1>
              <p id="logistycy">
                Logistyczka została przydzielona do sklepu spożywczego. Jej
                zadaniem było współprzyjmowanie dostaw, sprawdzanie daty
                przydatności produktów, metkowanie produktów, przenoszenie
                oraz uzupełnianie napojów w lodówkach, układanie na półkach
                różnego rodzaju towarów. Praca nie sprawiała żadnej trudności
                przy wykonywaniu. Byli tam bardzo mili i pomocni ludzie którzy
                zawsze doradzili jak będzie najlepiej i pomagali jak tylko
                mogli.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
