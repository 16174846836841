import { LinkHome } from "./LinkHome";
import SlideShowLarisa from "./SlideShowLarisa";
import SlideShowEdesa from "./SlideShowEdesa";
import SlideShowOlimp from "./SlideShowOlimp"
import SlideShowMeteory from "./SlideShowMeteory"

import "../Wycieczki.css";

const Wycieczki = () => {
  return (
    <>
      <div className="travels-wrapper">
        <div className="larisa2">
          <SlideShowLarisa />
          <div className="description-larisa">
            <h1>Larisa</h1>
            <p>
              Naszą pierwszą wyprawą była wycieczka do Larissy. Z samego rana
              wyruszyliśmy autokarem spod stacji kolejowej po około godzinie
              byliśmy na miejscu. Jako pierwszy punkt wycieczki odwiedziliśmy
              starożytny teatr Grecki z II w. p. n. e który jest wykonany w
              całości z marmuru następnie malowniczymi, uliczkami przeszliśmy do
              Prawosławnego kościoła, który od środka wyglądał przepięknie. Po
              wyjściu schodząc trochę niżej zobaczyliśmy niecodzienny pomnik
              konia. Był to bucefał - ulubiony ogier tesalski Aleksandra
              Wielkiego.
            </p>
            <br></br>
            <a id="galeria" href="./#/galeria">
              Zobacz więcej zdjęć z wycieczki!
            </a>
          </div>
        </div>
      </div>
      <div className="travels-wrapper">
        <div className="larisa">
          <div className="description-larisa">
            <h1>Olimp</h1>
            <p>
              Następnego w niedziele wyruszyliśmy na długą podróż. Pierwszym
              przestankiem była wioska Palaios Panteleimonas położona 700 m n.
              p. m Do tej malowniczej miejscowości jechaliśmy krętymi drogami z
              której podziwialiśmy przepiękne widoki na morze Egejskie. Historia
              samej miejscowości sięga XVI wieku, gdzie zaczęły wtedy powstawać
              pierwsze zabudowania o macedońskiej architekturze a między nimi
              rozchodziły się bardzo wąskie uliczki kolejnym przystankiem był
              zamek w Platamonas który został wybudowany w latach od 1204 do
              1222. 80 metrowa wieża zamku dawał piękny widok na wybrzeże morza
              Egejskiego. Na zakończenie udaliśmy się na spacer wąwozem Enipeas
              który prowadził do wodospadu Withos u podnóża góry Olimp.
            </p>
            <br></br>
            <a id="galeria" href="./#/galeria">
              Zobacz więcej zdjęć z wycieczki!
            </a>
          </div>
          <SlideShowOlimp />  
        </div>
      </div>
      <div className="travels-wrapper">
        <div className="larisa2">
          <SlideShowEdesa />
          <div className="description-larisa">
            <h1>Edesa i Pozar</h1>
            <p>
              Kolejną wycieczkę mieliśmy 6 maja. Na stracie wyprawy udaliśmy się
              do miasta wodospadów, czyli Edessy w tym mieście na powierzchni
              100 000 m2 znajduję się aż 11 wodospadów. My zobaczyliśmy
              największy z nich który ma aż 70 metrów wysokości. Masywność oraz
              ogrom wodospadu zrobił na nas bardzo duże wrażenie. Kolejnym
              przystankiem na naszej liście było miasto Pozar gdzie znajdują się
              gorące źródła, czyli baseny termalne. Spędziliśmy tam miło czas
              korzystając z tej magicznej wody.
            </p>
            <br></br>
            <a id="galeria" href="./#/galeria">
              Zobacz więcej zdjęć z wycieczki!
            </a>
          </div>
        </div>
      </div>
      <div className="travels-wrapper">
        <div className="larisa" id="meteory">
          <div className="description-larisa">
            <h1>Meteory</h1>
            <p>
              Na zakończenie naszego pobytu Grecji pojechaliśmy zobaczyć
              tajemnicze meteory. Ale najpierw w drodze do świątyń zatrzymaliśmy
              się w miejscowości Kalambaka gdzie znajdują się liczne sklepy i
              wytwórnie Ikon w tej której się zatrzymaliśmy wszystkie ikony są
              wytwarzane ręcznie, nie ma mowy o żadnych maszynach. Po wyjechaniu
              z miasta Kalambaka ukazały nam się zapierające wdech w piersiach
              Meteory, czyli w wolnym tłumaczeniu z Greckiego “zawieszony w
              powietrzu”. Pierwszy klasztor wybudowano 1356 roku teraz
              wszystkich ich jest 24, ale tylko 6 z nich są do teraz aktywne. My
              byliśmy w największym i najbardziej rozpoznawanym klasztorze,
              czyli Wielki Meteor w środku zobaczyliśmy przepiękne freski.
              Wycieczka była niesamowita i wciągająca. A miejsce, w którym
              byliśmy warte polecenia i zobaczenia na żywo.
            </p>
            <br></br>
            <a id="galeria" href="./#/galeria">
              Zobacz więcej zdjęć z wycieczki!
            </a>
          </div>
        <SlideShowMeteory />
        </div>
      </div>
      <LinkHome />
    </>
  );
};

export default Wycieczki;
