import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import '../SlideShowLarisa.css'

const fadeImages = [
  {
    url: 'img/Larisa/larisa1.jpg', alt: '1',
  },
  {
    url: 'img/Larisa/larisa2.jpg', alt: '2'
  },
];

export default function SlideShowLarisa() {
  return (
        <div className="larisa-slide-container">
          <Fade>
              {fadeImages.map((fadeImage, index) => (
              <div key={index}>
                  <img id='slider' alt={fadeImage.alt} src={fadeImage.url} />
              </div>
              ))}
          </Fade>
        </div>

  )
}
