import { LinkHome } from "./LinkHome";
import "../Opinie.css";
export default function Opinie() {
  return (
    <>
      <div className="opinie-container">
        <div className="info1">
          <div className="opinie">
            <img src="img/speek.png" id="speek"></img>
            <p id="siemandero">
              ~ Wyjazd był bardzo dobrze zorganizowany oraz wywarł na mnie spore
              wrażenie. Projekt dał mi możliwość większego usamodzielnienia się
              i sprawniejszego radzenia sobie z codziennymi obowiązkami. Dzięki
              wyjazdowi poznałem grecką kulturę oraz ważne informacje
              historyczne dotyczące tego kraju.
            </p>
          </div>
        </div>
        <div className="info1">
          <div className="opinie">
            <img src="img/speek.png" id="speek"></img>
            <p id="siemandero">
              ~ Udział w projekcie dał mi mnóstwo doświadczenia w zawodzie, jak
              i życiu prywatnym. Przez ten czas mieliśmy też sporo ciekawych i
              różnorodnych wycieczek. Cały pobyt wzbogacił mnie też oczywiście
              językowo, głównie angielski, ale też Grecki co na pewno bardzo
              pomoże mi w dalszym życiu zawodowym.
            </p>
          </div>
        </div>
        <div className="info1">
          <div className="opinie">
            <img src="img/speek.png" id="speek"></img>
            <p id="siemandero">
              ~ Pobyt na zagranicznych praktykach był dla mnie bardzo dużym
              przeżyciem i zarazem wielka przygodą. Jestem bardzo zadowolona z
              wyjazdu i będę wspominała pobyt tam bardzo dobrze. Niesamowicie
              się cieszę oraz bardzo jestem zadowolona, że dostałam szansę
              uczestniczyć w projekcie.
            </p>
          </div>
        </div>
        <div className="info1">
          <div className="opinie">
            <img src="img/speek.png" id="speek"></img>
            <p id="siemandero">
              ~ Jestem niesamowicie dumny i szczęśliwy z faktu, że mogłem brać
              udział w tych praktykach zagranicznych. Uważam, że każdy powinien
              dążyć do udziału w takim wyjeździe, ponieważ jest to niesamowita
              okazja na własny rozwój.
            </p>
          </div>
        </div>
      </div>
      <LinkHome />
    </>
  );
}
