import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import '../SlideShowEdesa.css'

const fadeImages = [
  {
    url: 'img/olimp/meteory1.jpg', alt: '1',
  },
  {
    url: 'img/olimp/meteory2.jpg', alt: '2'
  },
  {
    url: 'img/olimp/meteory3.jpg', alt: '1',
  },
  {
    url: 'img/olimp/meteory4.jpg', alt: '2'
  },
  {
    url: 'img/olimp/meteory5.jpg', alt: '1',
  },
  {
    url: 'img/olimp/meteory6.jpg', alt: '1',
  },
];

export default function SlideShowMeteory() {
  return (
        <div className="edesa-slide-container">
          <Fade>
              {fadeImages.map((fadeImage, index) => (
              <div key={index}>
                  <img id='slider' alt={fadeImage.alt} src={fadeImage.url} />
              </div>
              ))}
          </Fade>
        </div>

  )
}

