import "../Miasto.css";
import { LinkHome } from "./LinkHome";

export default function Miasto() {
  return (
    <>
      <div className="city-wrapper">
        <div className="city-description">
          <h1 id="pori">Nei Pori</h1>
          <p>
            Nei Poroi to małe turystyczne miasteczko w południowej części
            Grecji. Miasto zaludnia 700 mieszkańców, lecz w trakcie sezonu
            letniego, w mieście znajduje się o wiele więcej ludzi. W okresie w
            którym przyjechaliśmy do Nei Poroi miasto było opustoszałe, otwarty
            był tylko jeden sklep, a wszystkie bary i restauracje przy plaży
            były pozamykane. Co ciekawe, ulice w Nei Poroi są bardzo szerokie,
            jednak są jednopasmowe, a na skrzyżowaniach kierowcy parkują swoje
            samochody nie ponosząc żadnych konsekwencji . W kwestii zabudowania,
            to w mieście większość budowli to hotele, mniej lub bardziej
            ekskluzywne, w mieście znajduje się kościół prawosławny, który
            stanowi rynek i centrum miasta. Sama plaża była do naszej dyspozycji
            w całości przez pierwszy tydzień wyjazdu , aczkolwiek z dnia na
            dzień pojawiało się więcej tawern na plaży, oraz innych grup z
            Polski realizujących praktyki zawodowe.
          </p>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6337.770823502784!2d22.651133450000003!3d39.976783749999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a7f538b8a9ec89%3A0xa00bd2f74c50460!2sNeoi%20Poroi%20600%2063%2C%20Grecja!5e1!3m2!1spl!2spl!4v1685983832510!5m2!1spl!2spl"
          style={{
            width: "600px",
            height: "450px",
            loading: "lazy",
          }}
        ></iframe>
      </div>
      <div className="img-miasto-wrapper">
        <img
          src="img/miasto/miasto1.jpg"
          alt="miasto"
          id="img-miasto"
        ></img>
        <img
          src="img/miasto/miasto5.jpg"
          alt="miasto"
          id="img-miasto"
        ></img>
        <img
          src="img/miasto/miasto6.jpg"
          alt="miasto"
          id="img-miasto"
        ></img>
        <img
          src="img/miasto/miasto3.jpg"
          alt="miasto"
          id="img-miasto"
        ></img>
        <img
          src="img/miasto/miasto2.jpg"
          alt="miasto"
          id="img-miasto"
        ></img>
        <img
          src="img/miasto/miasto4.jpg"
          alt="miasto"
          id="img-miasto"
        ></img>
      </div>
      <LinkHome />
    </>
  );
}
