import {LinkHome} from "./LinkHome"
import {Plakat} from "../Plakat"
import "../Projekt.css"

export default function Projekt() {
  return (
    <>
      <LinkHome />
      <div className="poster"><Plakat /></div>
    </>
  )
};

