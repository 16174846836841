import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import '../SlideShowEdesa.css'

const fadeImages = [
  {
    url: 'img/olimp/olimp8.jpg', alt: '1',
  },
  {
    url: 'img/olimp/olimp9.jpg', alt: '2'
  },
  {
    url: 'img/olimp/olimp10.jpg', alt: '1',
  },
  {
    url: 'img/olimp/olimp11.jpg', alt: '1',
  },
  {
    url: 'img/olimp/olimp12.jpg', alt: '2'
  },
  {
    url: 'img/olimp/olimp13.jpg', alt: '1',
  },
  {
    url: 'img/olimp/olimp13.jpg', alt: '1',
  },
];

export default function SlideShowOlimp() {
  return (
        <div className="edesa-slide-container">
          <Fade>
              {fadeImages.map((fadeImage, index) => (
              <div key={index}>
                  <img id='slider' alt={fadeImage.alt} src={fadeImage.url} />
              </div>
              ))}
          </Fade>
        </div>

  )
}

