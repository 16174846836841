import { LinkHome } from "./LinkHome";
import "../Galeria.css";

export default function Galeria() {
  return (
    <>
      <div className="main-container">
        <div className="naglowek">
          <p id="naglowek">Czas Wolny</p>
        </div>
        <div className="galeria-wrapper">
          <div className="szeroki">
            <img src="img/wolne/k1.jpg"></img>
            <img src="img/wolne/k2.jpg"></img>
            <img src="img/wolne/k3.jpg"></img>
            <img src="img/wolne/k4.jpg"></img>
          </div>
          <div className="wysoki">
            <img id="pion" src="img/wolne/pion1.jpg"></img>
            <img id="pion" src="img/wolne/pion2.jpg"></img>
            <img id="pion" src="img/wolne/pion3.jpg"></img>
            <img id="pion" src="img/wolne/pion4.jpg"></img>
          </div>
          <div className="szeroki"></div>
          <div className="wysoki">
            <img id="pion" src="img/wolne/pion6.jpg"></img>
            <img id="pion" src="img/wolne/pion7.jpg"></img>
            <img id="pion" src="img/wolne/pion8.jpg"></img>
            <img id="pion" src="img/wolne/pion9.jpg"></img>
          </div>
          {/* <div className="naglowek">
            <p id="naglowek">Wycieczki</p>
          </div>
          <div className="galeria-wrapper">
            <div className="szeroki">
              <img src="img/olimp/meteory1.jpg"></img>
              <img src="img/olimp/meteory2.jpg"></img>
              <img src="img/olimp/meteory3.jpg"></img>
              <img src="img/olimp/meteory4.jpg"></img>
              <img src="img/olimp/meteory5.jpg"></img>
            </div>
            <div className="wysoki">
              <img src="img/olimp/meteory6.jpg"></img>
              <img src="img/olimp/olimp8.jpg"></img>
              <img src="img/olimp/olimp9.jpg"></img>
              <img src="img/olimp/olimp10.jpg"></img>
            </div>
            <div className="szeroki">
              <img src="img/olimp/olimp11.jpg"></img>
              <img src="img/olimp/olimp12.jpg"></img>
            </div>
            <div className="wysoki">
              <img src="img/olimp/olimp14.jpg"></img>
              <img src="img/olimp/olimp15.jpg"></img>
            </div>
          </div> */}
        </div>
      </div>
      <LinkHome />
    </>
  );
}
